// Avoid `console` errors in browsers that lack a console.
(function() {
  var method;
  var noop = function () {};
  var methods = [
    'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
}());

// Place any jQuery/helper plugins in here.
/*
* Javascript to show and hide cookie banner using localstroage
*/

/**
 * Shows the Cookie banner 
 */
function showCookieBanner(){
  let cookieBanner = document.getElementsByClassName("vot-cookie-banner")[0];
  cookieBanner.style.display = "block";
}

/**
* Hides the Cookie banner and saves the value to localstorage
*/
function hideCookieBanner(){
  localStorage.setItem("web_dev_isCookieAccepted", "yes");

  let cookieBanner = document.getElementsByClassName("vot-cookie-banner")[0];
  cookieBanner.style.display = "none";
}

/**
* Checks the localstorage and shows Cookie banner based on it.
*/
function initializeCookieBanner(){
  let isCookieAccepted = localStorage.getItem("web_dev_isCookieAccepted");
  if(isCookieAccepted === null)
  {
      localStorage.clear();
      localStorage.setItem("web_dev_isCookieAccepted", "no");
      showCookieBanner();
  }
  if(isCookieAccepted === "no"){
      showCookieBanner();
  }
}

// Assigning values to window object
window.onload = initializeCookieBanner();
window.vot_hideCookieBanner = hideCookieBanner;